export default
[
    /*{title: "InvoiceSummaries.Label.key", dataIndex: "key", key: "key", responsive: ['md']},*/
    {title: "InvoiceSummaries.Label.invoiceId", dataIndex: "invoiceId", key: "invoiceId", responsive: ['md']},
    /*{title: "InvoiceSummaries.Label.companyBranchId", dataIndex: "companyBranchId", key: "companyBranchId", responsive: ['md']},*/
    {title: "InvoiceSummaries.Label.companyBranchName", dataIndex: "companyBranchName", key: "companyBranchName", responsive: ['md']},
    {title: "InvoiceSummaries.Label.invoiceDataTime", dataIndex: "invoiceDataTime", key: "invoiceDataTime", responsive: ['md']},
    {title: "InvoiceSummaries.Label.serviceArDescription", dataIndex: "serviceArDescription", key: "serviceArDescription", responsive: ['md']},
    {title: "InvoiceSummaries.Label.carIdNumber", dataIndex: "carIdNumber", key: "carIdNumber", responsive: ['md']},
    {title: "InvoiceSummaries.Label.carDriverName", dataIndex: "carDriverName", key: "carDriverName", responsive: ['md']},
    {title: "InvoiceSummaries.Label.invoiceOdometer", dataIndex: "invoiceOdometer", key: "invoiceOdometer", responsive: ['md']},
   /* {title: "InvoiceSummaries.Label.serviceEnDescription", dataIndex: "serviceEnDescription", key: "serviceEnDescription", responsive: ['md']},*/
    {title: "InvoiceSummaries.Label.invoiceFuelConsumptionLiter", dataIndex: "invoiceFuelConsumptionLiter", key: "invoiceFuelConsumptionLiter", responsive: ['md']},
    {title: "InvoiceSummaries.Label.invoiceAmount", dataIndex: "invoiceAmount", key: "invoiceAmount", responsive: ['md']},
    {title: "InvoiceSummaries.Label.invoiceServiceFees", dataIndex: "invoiceServiceFees", key: "invoiceServiceFees", responsive: ['md']},
   
    {title: "InvoiceSummaries.Label.invoiceConsumption", dataIndex: "invoiceConsumption", key: "invoiceConsumption", responsive: ['md']},
    {title: "InvoiceSummaries.Label.invoiceConsumptionKmLt", dataIndex: "invoiceConsumptionKmLt", key: "invoiceConsumptionKmLt", responsive: ['md']},
    
    

    
  

   /* {title: "InvoiceSummaries.Label.paymentMethodName", dataIndex: "paymentMethodName", key: "paymentMethodName", responsive: ['md']}
   {title: "InvoiceSummaries.Label.stationNameAr", dataIndex: "stationNameAr", key: "stationNameAr", responsive: ['md']},
   */
]

